<template>
  <div>
      <div>
      <div :class="{showOverflow:isoverfy,item_list_data_xiadan:true}" >
       <div class="item_list_banner item_list_bannerBorder" v-for="(item,i) in placeData" :key="i">
          <div class="item_list_banner_select">
            <div class="item_list_banner_select">
              <div @click="clickRadite" :class="{raditebg:isRadite,radite:!isRadite}"></div>
              <div class="textInput">{{item.name}}</div>
            </div>
            <div class="item_list_banner_select_right" v-if="showDeleteHid">
              <div class="item_list_banner_select_icon right22">
                <img src="../../image/enditIcon.png" />
                <div class="item_list_banner_select_text">
                  修改
                </div>
              </div>
               <div class="item_list_banner_select_icon right22">
                <img src="../../image/deleteIcon.png" />
                <div class="item_list_banner_select_text">
                  删除
                </div>
              </div>
            </div>
          </div>
          <div class="item_list_banner_box">
            <img class="imgBox" src="../../image/xiaDan.png" alt="" srcset="">
            <div class="item_list_banner_Left">
              <div class="item_list_banner_LeftData">
                <div class="item_list_banner_LeftName">产品名称：</div>
                <div class="item_list_banner_LeftTitle">{{item.name}}</div>
              </div>
              <div class="item_list_banner_LeftData">
                <div class="item_list_banner_LeftName">批次号1：</div>
                <div class="item_list_banner_LeftTitle itemColor">{{item.pc1}}</div>
              </div>
               <div class="item_list_banner_LeftData">
                <div class="item_list_banner_LeftName ">批次号2：</div>
                <div class="item_list_banner_LeftTitle itemColor">{{item.pc2}}</div>
              </div>
               <div class="item_list_banner_LeftData">
                <div class="item_list_banner_LeftName ">入仓数量：</div>
                <div class="item_list_banner_LeftTitle itemColor">{{item.rcsl}}</div>
              </div>
            </div>
            <div class="item_list_banner_Rigth">
              <div class="item_list_banner_LeftData">
                <div class="item_list_banner_LeftName">规格：</div>
                <div class="item_list_banner_LeftTitle">{{item.guige}}</div>
              </div>
                <div class="item_list_banner_LeftData">
                <div class="item_list_banner_LeftName">生产日期：</div>
                <div class="item_list_banner_LeftTitle itemColor">{{item.guige}}</div>
              </div>
               <div class="item_list_banner_LeftData">
                <div class="item_list_banner_LeftName ">到期日期：</div>
                <div class="item_list_banner_LeftTitle itemColor">{{item.productDate}}</div>
              </div>
               <div class="item_list_banner_LeftData">
                <div class="item_list_banner_LeftName ">产品名称：</div>
                <div class="item_list_banner_LeftTitle itemColor">{{item.goodsName}}</div>
              </div>
            </div>
          </div>
       </div>
      </div>
     </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
            // 下单订单
      placeOrder: [
        {
          name: "阿米可牌罗勒酱复合调味料",
          pc1: "fd",
          pc2: "fd",
          rcsl: "fdrcsl",
          guige: "130g*12瓶",
          productDate: "fd",
          goodsName: "fd"
        }
      ],     
      isRadite: false,
      
    }
  },
  props:{
    placeData:{
      type:Array,
      required:true,
    },
    showDeleteHid:{
      type:Boolean,
      required:false,
    },
    isoverfy: {
      type:Boolean,
      required:false,
    }
  },
  methods: {
      clickRadite() {
      this.isRadite = !this.isRadite;
    },
  },
}
</script>
<style lang="less" scoped>
  .showOverflow {
      overflow: auto;
  }
  .item_list_data_xiadan {
  position: relative;
  width: 100%;
  height: 998px;
  padding: 21px 21px 21px 21px;
  // overflow: auto;
  .item_list_bannerBorder {
    border: 1px solid #ecb49b;
    padding: 23px 21px 19px 23px;
    margin-bottom: 20px;
  }
  .item_list_banner_select {
    .raditebg {
      border-radius: 50%;
      background-color: #fd4d02;
      width: 14px;
      height: 14px;
    }
    .radite {
      border-radius: 50%;
      border: 1px solid #666666;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
    .textInput {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin-left: 15px;
    }
    display: flex;
    // justify-content: center;
    align-items: center;
    justify-content: space-between;
    .item_list_banner_select_right {
      display: flex;
      .item_list_banner_select_icon {
        display: flex;
        cursor: pointer;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fd4d02;
        .item_list_banner_select_text {
          margin-left: 3px;
        }
      }
      .right22 {
        margin-right: 22px;
      }
    }
  }
  .item_list_banner_box {
    margin-top: 15px;
    display: flex;
    .imgBox {
      width: 83px;
      height: 83px;
    }
    .item_list_banner_Rigth,
    .item_list_banner_Left {
      display: flex;
      flex-direction: column;
      .item_list_banner_LeftData {
        display: flex;
        margin-left: 21px;
        .item_list_banner_LeftName {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
        }
        .item_list_banner_LeftTitle {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fd4d02;
          margin-left: 8px;
        }
        .itemColor {
          color: #666666;
        }
      }
    }
    .item_list_banner_Rigth {
      margin-left: 23px;
    }
  }
}

</style>

